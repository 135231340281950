/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import { Container, Badge, Card, CardBody, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "../components/Navbars/DemoNavbar";
import SimpleFooter from "../components/Footers/SimpleFooter";

// index page sections
import Hero from "./IndexSections/Hero";
import Modals from "./IndexSections/Modals";
import { getProjectIDByName, getSaleEnabled } from "../utils";

const PROJECT_NAME = "Society Laid Bear";

const Index = (props) => {
  const ws = useRef<WebSocket | null>(null);
  useEffect(() => {
    return () => {
      closeWsConn();
    };
  }, []);

  const openWsConn = (prID: string) => {
    ws.current = new WebSocket(
      "wss://" + window.location.hostname + "/ws?id=" + prID
    );

    ws.current.onopen = () => {
      console.log("CONN OPEN");
    };
    ws.current.onmessage = (e: any) => {
      var msg = JSON.parse(e.data);
      console.log("OnMessage", msg);
      switch (msg.type) {
        case "TRANSACTION_SUCESSFUL":
          props.history.push("/success?id=" + prID);
          closeWsConn();
          break;
        default:
          break;
      }
    };
    ws.current.onclose = () => {
      closeWsConn();
    };
  };

  const closeWsConn = () => {
    console.log("CLOSING CONN");
    ws.current?.close();
    ws.current = null;
  };

  const [projectID, setProjectID] = useState("dummy");
  const [saleEnabled, setSaleEnabled] = useState(false);
  useEffect(() => {
    // DISABLED
    return

    getProjectIDByName(PROJECT_NAME).then((projectID) => {
      setProjectID(projectID);
    });
    getSaleEnabled().then((enabled) => {
      setSaleEnabled(enabled);
    });
  }, []);

  return (
    <>
      <DemoNavbar />
      <main>
        {projectID && <Hero projectID={projectID} />}
        {projectID && (
          <Modals
            projectID={projectID}
            openWsConn={openWsConn}
            saleEnabled={saleEnabled}
          />
        )}
        <section className="section section-lg">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={require("assets/img/comic-set.png")}
                />
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                    <i className="fa fa-flag" />
                  </div>
                  <h3>Meet the Nerd Society</h3>

                  <p>
                    The first set in the Society Laid Bear collection, the Nerd
                    Society represents the geek in all of us, whilst adding some
                    humour here and there.
                  </p>
                  <ul className="list-unstyled mt-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="fa fa-stack-overflow" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">1,000 Total cards in the set</h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="fa fa-list-ol" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">10 Types of bear</h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-satisfied" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">6 Tiers of rarity</h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-settings-gear-65" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Automatic distribution system
                          </h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pb-0 bg-gradient-dark">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-lg-2 ml-lg-auto" md="6">
                <div className="position-relative pl-md-5">
                  <video
                    playsInline
                    autoPlay
                    muted
                    loop
                    id="bgvid"
                    preload="none"
                    className="img-fluid mx-auto d-block"
                  >
                    <source
                      src={require("assets/img/programmer-gold-dark.mp4")}
                      type="video/mp4"
                    ></source>
                  </video>
                </div>
              </Col>
              <Col className="order-lg-1" lg="6">
                <div className="d-flex px-3">
                  <div>
                    <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                      <i className="fa fa-star text-warning" />
                    </div>
                  </div>
                  <div className="pl-4">
                    <h4 className="display-3 text-white">
                      Will you get the Golden Bear?
                    </h4>
                    <p className="text-white">
                      Each type of bear has the one of a kind legendary golden
                      variant, that means only 10 exist in the entire set!
                    </p>
                  </div>
                </div>
                <Card className="shadow shadow-lg--hover mt-5">
                  <CardBody>
                    <div className="d-flex px-3">
                      <div>
                        <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                          <i className="ni ni-satisfied" />
                        </div>
                      </div>
                      <div className="pl-4">
                        <h5 className="title text-success">Automated Sales</h5>
                        <p>
                          We developed an CNFT vending machine, fast and
                          automated which ensures random distribution for
                          everyone.
                        </p>
                        <a
                          className="text-success"
                          href="#rarity"
                          onClick={(e) => e.preventDefault()}
                        >
                          Buy a bear
                        </a>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="shadow shadow-lg--hover mt-5">
                  <CardBody>
                    <div className="d-flex px-3">
                      <div>
                        <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                          <i className="fa fa-slack" />
                        </div>
                      </div>
                      <div className="pl-4">
                        <h5 className="title text-warning">60 Variants</h5>
                        <p>
                          Although the Nerd Society makes up just the beginning
                          of the "Society Laid Bear" collection, their is still
                          60 variants up for grabs.
                        </p>
                        <a
                          className="text-warning"
                          href="#rarity"
                          onClick={(e) => e.preventDefault()}
                        >
                          Check the rarity table below
                        </a>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        <section className="section section-components pt-0">
          <Container>
            <h2 className="mt-5">Rarity Table</h2>
            <div className="table-responsive">
              <table className="table table-striped table-bordered table-sm table-dark ">
                <thead>
                  <tr>
                    <th scope="col">Bear</th>
                    <th scope="col">Brown</th>
                    <th scope="col">Black</th>
                    <th scope="col">Polar</th>
                    <th scope="col">Panda</th>
                    <th scope="col">Rainbow</th>
                    <th scope="col">Gold</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Programmer</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Gamer</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Comic Book</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Cosplayer</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Sci-Fi Fan</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Grandmaster</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Dungeon Master</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Card Collector</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Keyboard Warrior</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Astrophysicist</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Container>
        </section>
      </main>
      <img
        alt="..."
        className="img-fluid mx-auto d-block"
        src={require("assets/img/panda-programmer.png")}
        style={{ width: "" }}
      />
      <SimpleFooter />
    </>
  );
};

export default Index;
