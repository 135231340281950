/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// reactstrap components
import {
  FormGroup,
  Input,
  Button,
  Badge,
  Modal,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import QRCode from "qrcode.react";
import { PurchaseRequest, createPurchaseRequest } from "../../utils";

const Modals = (props) => {
  const [purchaseReq, setPurchaseReq] = useState<PurchaseRequest>();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalQuantity, setModalQuantity] = useState(0);

  const startPurchaseRequest = (amount) => {
    // DISABLED
    return

    if (!props.saleEnabled) {
      return;
    }
    createPurchaseRequest(props.projectID, amount).then((pr) => {
      setPurchaseReq(pr);
      setModalQuantity(amount);
      toggleModal();
      props.openWsConn(pr.id);
    });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <section className="section section-lg pt-lg-0 mt--100">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <Row className="row-grid">
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="pb-5">
                      <div className="mb-4">
                        <img
                          alt="..."
                          className="img-fluid floating mx-auto d-block"
                          src={require("assets/img/1pack.png")}
                        />
                      </div>
                      <h6 className="text-primary text-uppercase">
                        Single Random Bear Pack
                      </h6>
                      <p className="description mt-3">
                        Buy a random single bear from the Nerd Society set, our
                        system is automatic so you will not wait long to receive
                        your CNFT.
                      </p>
                      <div>
                        <Badge color="primary" pill className="mr-1">
                          Price:
                        </Badge>
                        <Badge color="primary" pill className="mr-1">
                          28 ADA
                        </Badge>
                      </div>
                      <Button
                        className="mt-4"
                        color="primary"
                        onClick={() => startPurchaseRequest(1)}
                      >
                        {props.saleEnabled?"Purchase":"SOLD OUT"}
                      </Button>
                      <div className="warningSmall pt-3">
                        By purchasing you understand that you must not send
                        funds from an exchange, and must use a Cardano
                        Shelley-Era wallet.
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="pb-5">
                      <div className="mb-4">
                        <img
                          alt="..."
                          className="img-fluid floating mx-auto d-block"
                          src={require("assets/img/3pack.png")}
                        />
                      </div>
                      <h6 className="text-success text-uppercase">
                        3 Random Bears Pack
                      </h6>
                      <p className="description mt-3">
                        Buy 3 random bears from the Nerd Society set. Our system
                        is automatic so you will not wait long to receive your
                        CNFT.
                      </p>
                      <div>
                        <Badge color="success" pill className="mr-1">
                          PRICE:
                        </Badge>
                        <Badge color="success" pill className="mr-1">
                          84 ADA
                        </Badge>
                      </div>
                      <Button
                        className="mt-4"
                        color="primary"
                        onClick={() => startPurchaseRequest(3)}
                      >
                        {props.saleEnabled?"Purchase":"SOLD OUT"}
                      </Button>
                      <div className="warningSmall pt-3">
                        By purchasing you understand that you must not send
                        funds from an exchange, and must use a Cardano
                        Shelley-Era wallet.
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="pb-5">
                      <div className="mb-4">
                        <img
                          alt="..."
                          className="img-fluid floating mx-auto d-block"
                          src={require("assets/img/5pack.png")}
                        />
                      </div>
                      <h6 className="text-warning text-uppercase">
                        5 Random Bears Pack
                      </h6>
                      <p className="description mt-3">
                        Buy 5 random bears from the Nerd Society set. Our system
                        is automatic so you will not wait long to receive your
                        CNFT.
                      </p>
                      <div>
                        <Badge color="warning" pill className="mr-1">
                          Price:
                        </Badge>
                        <Badge color="warning" pill className="mr-1">
                          140 ADA
                        </Badge>
                      </div>
                      <Button
                        className="mt-4"
                        color="primary"
                        onClick={() => startPurchaseRequest(5)}
                      >
                        {props.saleEnabled?"Purchase":"SOLD OUT"}
                      </Button>
                      <div className="warningSmall pt-3">
                        By purchasing you understand that you must not send
                        funds from an exchange, and must use a Cardano
                        Shelley-Era wallet.
                      </div>
                    </CardBody>
                  </Card>
                  <Modal
                    className="modal-dialog-centered modal-primary"
                    contentClassName="bg-gradient-primary"
                    isOpen={modalOpen}
                    toggle={toggleModal}
                  >
                    <div className="modal-header">
                      <h6
                        className="modal-title"
                        id="modal-title-notification3"
                      >
                        Purchase Pack Containing {modalQuantity} Random{" "}
                        {modalQuantity > 1 ? "Cards" : "Card"}
                      </h6>
                      <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                      >
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="py-3 text-center">
                        <i className="ni ni-basket ni-3x" />
                        <FormGroup>
                          <label>
                            Please send {purchaseReq?.price} ADA to the address
                            below.
                          </label>
                          <Input
                            disabled
                            placeholder={
                              "Amount: " + purchaseReq?.price + " ADA"
                            }
                            type="text"
                          />
                        </FormGroup>
                        <FormGroup className="has-success">
                          <Input
                            readOnly
                            className="is-valid"
                            placeholder={purchaseReq?.address}
                            type="text"
                            value={purchaseReq?.address}
                          />
                        </FormGroup>
                        <QRCode value={purchaseReq?.address} />
                        <p className="pt-1">
                          Once your ADA has been sent, please wait for this box
                          to update. If you click away from this tab you may not
                          be redirected to view your new cards, rest assured
                          your NFTs will still be delievered to your wallet.
                        </p>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                      >
                        Close
                      </Button>
                    </div>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Modals;
