/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer">
          <Container>
          <video playsInline autoPlay muted loop poster={require("assets/img/footer-still.jpg")} id="bgvid" preload="none" className="videofooter">
<source src={require("assets/img/footer.webm")} type="video/webm"></source><source src={require("assets/img/footer.mp4")} type="video/mp4"></source></video>
            <Row className=" row-grid align-items-center mb-5">
              
              <Col lg="8">
                <h3 className="footer_title mb-2 text-center">
                  Let's Make Something Great Together
                </h3>
                <h4 className="text-white mb-0 font-weight-light text-center">
                  hello@cardano.art
                </h4>
              </Col>
              <Col className="text-lg-center btn-wrapper " lg="4">
              <img
                          alt="..."
                          className="img-fluid mx-auto d-block mb-3"
                          width="200px"
                          src={require("assets/img/brand/logo-dark.svg")}
                          />
                          <div className="d-flex justify-content-center">
                <Button
                  className="btn-icon-only rounded-circle "
                  color="twitter"
                  href="https://twitter.com/CardanoArt"
                  id="tooltip475038074"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-twitter" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="instagram"
                  href="https://instagram.com/CardanoArt"
                  id="tooltip837440414"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-instagram" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Follow us
                </UncontrolledTooltip>
                </div>
              </Col>
            </Row>
            <Row className="row-grid align-items-center m-0">
              <Col lg="12">
                <div className="text-white text-center">
                Copyright © 2021 Cardano Art | All rights reserved.
                </div>
              </Col>
            </Row>
            
          
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
