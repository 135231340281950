export interface NFT {
  id: string;
  name: string;
  no: number;
  price: number;
  link: string;
  meta: MetaData;
  CreatedAt: string;
}

export interface MetaData {
  id: string;
  name: string;
  image: string;
  mediaType: string;
  description: string;
  files: MetaDataFile[];
  collection: string;
  set: string;
  bear: string;
  rarity: string;
  authors: string;
  website: string;
}

export interface MetaDataFile {
  mediaType: string;
  src: string;
}

const requestImageFile = require.context(
  "../assets/img/bears",
  false,
  /.(png|mp4)$/
);

export const getBearPreviewPath = (metadata: MetaData): string => {
  let fileExt = "";
  switch (metadata.files[0].mediaType) {
    case "image/png":
      fileExt = "png";
      break;
    case "video/mp4":
      fileExt = "mp4";
      break;
  }

  let bear = metadata.bear.toLowerCase()
  switch (metadata.bear.toLowerCase()) {
    case "scifi":
      bear = "sci-fi"
      break;
  }

  return requestImageFile(
    `./${bear}-${metadata.rarity.toLowerCase()}-preview.${fileExt}`
  );
};

