/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the
Software.

*/
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes

// reactstrap components
import { FormGroup, Input, Container, Row, Col } from "reactstrap";

import Tilt from "react-parallax-tilt";
import Zoom from "react-reveal/Zoom";
import queryString from "query-string";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "../components/Footers/SimpleFooter";
import { getBearPreviewPath, getCompletedPurchaseRequest, NFT } from "utils";

const buildCardGrid = (nfts: NFT[]) => {
  const cardsRows: JSX.Element[] = [];
  const maxCardsPerRow = 3;
  const rows = Math.ceil(nfts.length / maxCardsPerRow);

  for (let i = 0; i < rows; i++) {
    const cols: JSX.Element[] = [];
    for (let ii = 0; ii < maxCardsPerRow; ii++) {
      const nftIdx = i * maxCardsPerRow + ii;
      if (nftIdx >= nfts.length) break;

      const imgPath = getBearPreviewPath(nfts[nftIdx].meta);

      cols.push(
        <Col key={`cardcol_${nftIdx}`} md="4" className="pt-5">
          <Zoom left delay={nftIdx * 1000}>
            <Tilt
              glareEnable={true}
              glareMaxOpacity={0.5}
              glareColor="white"
              glarePosition="all"
            >
              {imgPath.endsWith(".mp4") ? (
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  className="img-fluid"
                >
                  <source src={imgPath} type="video/mp4"></source>
                </video>
              ) : (
                <img alt="..." className="img-fluid" src={imgPath} />
              )}
            </Tilt>
          </Zoom>
        </Col>
      );
    }

    cardsRows.push(
      <Row key={`cardrow_${i}`} className="row-grid align-items-center">
        {cols}
      </Row>
    );
  }

  return cardsRows;
};

const Success = (props) => {
  const [returnAddress, setReturnAddress] = useState("");
  const [nfts, setNfts] = useState<NFT[]>([]);

  useEffect(() => {
    // DISABLED
    return
    
    const { id } = queryString.parse(props.location.search);
    getCompletedPurchaseRequest(id as string).then((request) => {
      setReturnAddress(request.returnAddress);
      setNfts(request.nfts);
    });
  }, []);

  const cardsRows = buildCardGrid(nfts);

  return (
    <>
      <DemoNavbar />
      <main>
        <div className="position-relative">
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped">
            <video
              playsInline
              autoPlay
              muted
              loop
              poster={require("assets/img/header-bg.jpg")}
              id="bgvid"
              preload="none"
              className="videofooter"
            >
              <source
                src={require("assets/img/header-bg.webm")}
                type="video/webm"
              ></source>
              <source
                src={require("assets/img/header-bg.mp4")}
                type="video/mp4"
              ></source>
            </video>
            <Container className="py-lg-md d-flex">
              <div className="col px-0 mt-5 pt-5">
                <Row>
                  <Col lg="7">
                    <h1 className="display-3 text-white">
                      Thank you, <span>Your purchase has been successful!</span>
                    </h1>
                    <p className="lead text-white">
                      Your bears have been generated randomly and sent to your
                      wallet. Scroll down to see which bears you have unpacked.
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>

          <section className="section section-lg">
            <Container>
              <h3>Your cards have been sent to wallet address:</h3>
              <FormGroup className="has-success">
                <Input
                  readOnly
                  className="is-valid"
                  placeholder={returnAddress}
                  type="text"
                  value={returnAddress}
                />
              </FormGroup>

              {cardsRows}
            </Container>
          </section>
        </div>
        <section className="section section-components pt-0">
          <Container>
            <h2 className="mt-5">Rarity Table</h2>
            <div className="table-responsive">
              <table className="table table-striped table-bordered table-sm table-dark ">
                <thead>
                  <tr>
                    <th scope="col">Bear</th>
                    <th scope="col">Brown</th>
                    <th scope="col">Black</th>
                    <th scope="col">Polar</th>
                    <th scope="col">Panda</th>
                    <th scope="col">Rainbow</th>
                    <th scope="col">Gold</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Programmer</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Gamer</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Comic Book</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Cosplayer</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Sci-Fi Fan</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Grandmaster</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Dungeon Master</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Card Collector</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Keyboard Warrior</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Astrophysicist</th>
                    <td>45</td>
                    <td>24</td>
                    <td>15</td>
                    <td>10</td>
                    <td>5</td>
                    <td>1</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
};

export default Success;
