/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { fetchNftStockByProject, SoldDetails } from "../../utils";

const Hero = (props) => {
  const [stock, setStock] = useState<SoldDetails>({
    locked: 0,
    sold: 1000,
    total: 1000,
  });

  useEffect(() => {
    // DISABLED
    return;
    
    fetchNftStockByProject(props.projectID).then((stock) => setStock(stock));
  }, []);

  return (
    <>
      <div className="position-relative">
        {/* Hero for FREE version */}
        <section className="section section-hero section-shaped">
          <video
            playsInline
            autoPlay
            muted
            loop
            poster={require("assets/img/header-bg.jpg")}
            id="bgvid"
            preload="none"
            className="videofooter"
          >
            <source
              src={require("assets/img/header-bg.webm")}
              type="video/webm"
            ></source>
            <source
              src={require("assets/img/header-bg.mp4")}
              type="video/mp4"
            ></source>
          </video>

          <Container className="shape-container d-flex align-items-center py-lg">
            <div className="col px-0">
              <Row className="align-items-center justify-content-center">
                <Col className="text-center" lg="6">
                  <h2 className="text-white">
                    Welcome To The Society Laid Bear Card Store
                  </h2>
                  <div className="btn-wrapper mt-5">
                    <Button
                      className="btn-white btn-icon mb-3 mb-sm-0"
                      color="default"
                      href="https://discord.gg/fBVfQhzJTF"
                      size="lg"
                      target="_blank"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-button-play" />
                      </span>
                      <span className="btn-inner--text">Join Discord</span>
                    </Button>{" "}
                    <Button
                      className="btn-icon mb-3 mb-sm-0"
                      color="github"
                      href="https://cardano.art/collections/society-laid-bear/nerd-society/"
                      size="lg"
                      target="_blank"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-palette" />
                      </span>
                      <span className="btn-inner--text">
                        <span className="text-warning mr-1">Browse</span>
                        Nerd Society Set
                      </span>
                    </Button>
                  </div>
                  <p className="lead text-white">
                    The current set available is: <b>Nerd Society</b>
                  </p>
                  <p className="lead text-white">
                    The next set is: <b>Criminal Society</b> (ETA: Q4 2021)
                  </p>
                  <div className="mt-0">
                    <p className="lead text-white font-weight-bold">
                      Sold: {stock.sold}/{stock.total}
                    </p>
                    <small className="text-white font-weight-bold mb-0 mr-2">
                      Only 1000 CNFT's will be minted in the Nerd Society set.
                    </small>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </div>
    </>
  );
};

export default Hero;
